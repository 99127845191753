<div id="containerBody2">

  <div class="color-layer">

    <div class="row align-items-center mt-3">

      <ng-container *ngIf="isSmallScreen">
        <div class="col">

          <div class="row pl justify-content-center">
            <div class="col-auto">
              <span (click)="clientLogin()" class="custom-link">
                <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
              </span>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="row pr text-end justify-content-center">
            <div class="col-auto">
            <button (click)="register()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">Register For Free</button>
            <!-- <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">HR / Recruiter Login</button> -->
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isSmallScreen">
        <div class="col-6">

          <div class="row pl-3 justify-content-center">
            <div class="col-12">

              <span (click)="clientLogin()" class="custom-link">
                <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
              </span>
                  
            </div>
          </div>
        </div>
  
        <div class="col-6">

          <div class="row pr-3 text-end justify-content-center">
            <div class="col-12">
              <button (click)="register()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">Register For Free</button>
                   <!-- <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">HR / Recruiter Login</button> -->
            </div>
          </div>
        </div>

      </ng-container>

    </div>

    <div class="row justify-content-md-center pt-180px">
      <div class="col-md-5 login-title">
        Let AI refine you a resume
      </div>
    </div>
  
    <div class="row mt-5 justify-content-md-center">
      <div class="col-auto login-title2">
        Align a RESUME to any JOB DESCRIPTION with one click -
        Increase your placement velocity 10 fold!
      </div>
    </div>
    <!-- <div class="row mt-5 justify-content-md-center">
      <div class="col-auto login-title3">
        Increase your placement velocity 10 fold
      </div>
    </div> -->


    <div class="row mt-5">
  
      <div class="col-md-3">
      </div>
      
      <div class="col-md-6">

        <div class="row mt-3 justify-content-center align-items-center">

          <div class="col-auto">
            <!-- <a class="btn btn-primary btn-block rounded-pill shadow-sm p-2" href="http://75.98.202.121:8800/client/authorize/">Login in with Linkedin</a> -->
            <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill shadow-sm p-2">HR / Recruiter Login</button>
          </div>

          <div class="col-auto">
            <span class="learn-more">
             <a href="assets/static/learn-more/index.html" class="learn-more"> Learn more</a>
            </span>
          </div>

        </div>

      </div>
  
      <div class="col-md-3">
      </div>
  
    </div>
  
    <div class="row justify-content-md-center mt-5">
      <div class="col-auto">

        <div class="row align-items-center justify-content-md-center">
          <div class="col">
            <img src="assets/img/three-person-group.png" alt="4K">
          </div>

          <div class="col">


            <div class="row">
              <div class="col-md-auto">
                <img src="assets/img/4K+.png" alt="Image 4" class="login-4k">
              </div>
            </div>

            <div class="row">
              <div class="col-md-auto">
                <span class="users-worldwide">
                  professionals served
                </span>
              </div>
            </div>

          </div>
          

          <!-- <div class="col-md-5 pl-3">
            <div class="image-container">
              <img src="assets/img/person1.png" alt="Image 1" class="overlay-image">
              <img src="assets/img/person2.png" alt="Image 2" class="overlay-image2">
              <img src="assets/img/person3.png" alt="Image 3" class="overlay-image3">
            </div>
          </div>

          <div class="col-md-7">
            
            <div class="row">
              <div class="col-md-auto">
                <img src="assets/img/4K+.png" alt="Image 4" class="login-4k">
              </div>
            </div>

            <div class="row">
              <div class="col-md-auto">
                <span class="users-worldwide">
                  users worldwide
                </span>
              </div>
            </div>
            
          </div> -->

        </div>
        
      </div>

    </div>
  
  </div>

</div>


<p-dialog [styleClass]="customDialogClass" [draggable]="true" [resizable]="false" [closable]="false"  
[modal]="true" header="" [(visible)]="forVanity" 
[styleClass]="displayDialog ? 'responsive-dialog' : ''"
>

<div class="row">
  <div class="col-md-12">

    <span (click)="cancelVanityLogin()"  class="custom-link" pTooltip="Cancel Login" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}">
      <i class="fa fa-remove large_icon" aria-hidden="true"></i> 
    </span>

  </div>
</div>

  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">

        <form [formGroup]="vanityForm">
          <div class="row">
            <div class="col-md-12 text-center">

              <span class="enter-vanity">
                Enter Your Vanity Name
              </span>
            </div>

            <div class="col-md-12 text-center">
              <span class="linkedin-url">
                (e.g https://linkedin.com/in/<span class="url-bold">fname-lname-123</span>)
             </span>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center curved">
              <div class="form-group mt-4">
                  <input  id="vanityform" type="text" required="" autofocus="" class="form-control curved" formControlName="vanityform">
              </div>
              <div class="text-danger mt-2" *ngIf="submitted && (vanityForm.controls['vanityform'].invalid || 
                (vanityForm.controls['vanityform'].errors && vanityForm.controls['vanityform'].errors['required'])
                )">
                Vanity Name is required
              </div>

            </div>
          </div>

          <!-- start OR -->
         <div class="row mt-4 text-center">

            <div class="col">
              <div class="centered-line">
                <span>&nbsp;</span>
              </div>
            </div>

            <div class="col-auto">
              <span class="or">OR</span>
            </div>

            <div class="col">
              <div class="centered-line">
                <span>&nbsp;</span>
              </div>
            </div>


          </div> 
          <!-- end OR -->

          <!-- ////upload file section ////-->
          <div class="row text-center mt-4">          
            <div *ngIf="fileSelected" class="col-12">
              <span class="mr-2">{{selectedFile.name}}</span><span (click)="removeSelectedPDF()" class="custom-link">
                <i pTooltip="Remove/Replace" tooltipPosition="{{TOOLTIP_DEFAULT_POSITION}}" class="fa fa-refresh" aria-hidden="true"></i></span> 
            </div> 
          

            <!-- start file NOT selected  -->
            <div *ngIf="!fileSelected" class="col-12">

              <div class="clickable-box" (click)="handleBoxClick()">
                <input #fileInput id="fileInput" (click)="fileInput.value = ''" type="file" (change)="handleFileInput($event)" [hidden]="true" accept=".pdf">

                <div class="row pt-5 pb-5">

                  <div class="col-12">
                    <i class="fa  fa-upload" aria-hidden="true"></i> 

                  </div>

                  <div class="col-12">
                    <span class="upload">Upload PDF</span>
                  </div>
                </div>
                
              </div>


            </div> 
            <!-- end file NOT selected  -->
 </div> 
 <!--///////////////////////////////////////////////////////////-->
          <div class="row text-center">
            <div class="col-12">

              <button class="btn btn-primary btn-block mb-4 rounded-pill submit-btn shadow-sm mt-4 p-2" (click)="submitVanityInfo()" [disabled]="processing || loading">Upload
                <div *ngIf="processing || loading" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Processing...</span>
                </div>
              </button>

            </div>

          </div>



        </form>
    </div>
    <div class="col-md-2"></div>
  </div>
 
</p-dialog>





<p-dialog [style]="{ 'height': '500px' }" [draggable]="true" [resizable]="true" [closable]="false"  
[modal]="true"  [(visible)]="userAgreementModal" 
[styleClass]="displayDialog ? 'responsive-dialog' : ''"
>

<p-header>
  <div class="row">
    <div class="col-md-12 text-left">
      <h5 class="modal-title" id="exampleModalLabel"></h5>
    </div>
  </div>
</p-header>

<div class="scrollable-content" (scroll)="onScroll($event)">
  <!-- Your big scrollable text goes here -->

  <div class="row">
    <div class="col-12 text-center">
      <h3>
        USER AGREEMENT
      </h3>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        This User Agreement ("Agreement") is a legal agreement between you ("User" or "you") and MLSoft Technologies Inc ("Company" or "we") governing your use of the website and its services offered by the Company. By accessing or using the Company's website, you agree to be bound by this Agreement.
      </span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h5>
        User Responsibilities
      </h5>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>1.1</b> Vanity-Name: You confirm that you have provided your own LinkedIn vanity-name accurately and truthfully. You understand and acknowledge that it is your responsibility to ensure that the vanity-name you provide to the system is correct and corresponds to your identity.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>1.2</b> Liability for Incorrect Vanity-Name: You shall bear full responsibility for providing an accurate and correct vanity-name. In the event that an incorrect vanity-name is provided, the system may inadvertently scrape and collect data belonging to a different individual. Consequently, your profile may not reflect your own personal information.
      </span>
    </div>
  </div>
    
  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Rights and Obligations
      </h5>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.1</b> Ownership: You acknowledge that you have no ownership rights or claims over the system, its software, or any data collected or stored by the Company.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.2</b> License: Subject to your compliance with this Agreement, the Company grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the system solely for your personal or professional purposes.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.3</b> Data Scrapping and Exclusions: The Company reserves the right to employ automated processes to scrape data from LinkedIn profiles. However, the Company does not warrant the accuracy, reliability, or timeliness of the scraped data and shall not be held liable for any errors or discrepancies arising therefrom.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>2.4</b> Profile Reflection: The Company strives to ensure that your profile accurately reflects your self. However, in cases where an incorrect vanity-name is provided, the Company shall not be responsible for reflecting your personal information accurately. The responsibility for maintaining an accurate profile solely rests upon you.
      </span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Limited Liability and Indemnification
      </h5>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>3.1</b> Limitation of Liability: To the extent permitted by law, the Company, its officers, directors, employees, and affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the system, including any damages resulting from incorrect vanity-name provision or inaccurate profile reflection.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>3.2</b> Indemnification: You agree to indemnify, defend, and hold harmless the Company and its affiliates from and against any claims, liabilities, damages, losses, and expenses (including reasonable attorney fees) arising out of or related to any breach of this Agreement or your use of the system.
      </span>
    </div>
  </div>
    
  <div class="row mt-3">
    <div class="col-12">
      <h5>
        Miscellaneous
      </h5>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.1</b> Entire Agreement: This Agreement constitutes the entire understanding and agreement between you and the Company relating to your use of the system and supersedes any prior or contemporaneous agreements, communications, or understandings, whether oral or written.
      </span>
    </div>
  </div>

  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.2</b> Severability: If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        <b>4.3</b> Governing Law and Jurisdiction: This Agreement shall be governed by and construed in accordance with the laws of the USA and Canada. Any disputes arising out of or in connection with this Agreement shall be exclusively submitted to the competent courts of the USA/Canada.
      </span>
    </div>
  </div>
    
  <div class="row mt">
    <div class="col-12">
      <span>
        By accessing or using the system, you acknowledge that you have read, understood, and agreed to this User Agreement.
      </span>
    </div>
  </div>

</div>

<p-footer>
  <div class="row">
    <div class="col-md-12">
      
      <div  class="row">
        <div class="col">
        </div>
        <div class="col-auto">
          <button #closeContactsModal (click)="cancelAgree()" type="button" class="btn btn-secondary">I do not agree</button>
        </div>
        <div class="col-auto">
          <button [disabled]="agreeBtnDisabled || processingConfirm" type="button" class="btn btn-primary" (click)="confirmAgree()">
            I AGREE
            <div *ngIf="processingConfirm" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Processing...</span>
            </div>
          </button>
        </div>
      </div>

    </div>

  </div>
</p-footer>

</p-dialog>
